<template>
  <en-dialog :model-value="modelValue" title="单据历史" center @close="$emit('update:model-value', false)" width="50%">
    <en-collapse>
      <en-collapse-item title="单据基础信息">
        <span>单据编号：{{ data?.serialNo }}</span>
      </en-collapse-item>
      <en-collapse-item title="单据历史信息">
        <en-table :data="data?.logs">
          <en-table-column label="操作人" prop="operator.name"></en-table-column>
          <en-table-column label="描述" prop="message"></en-table-column>
          <en-table-column label="时间" prop="">
            <template #default="{ row }: { row: EnospraySprayDefinitions['DocumentLogDto'] }">{{ formatDate(row.datetime) }}</template>
          </en-table-column>
        </en-table>
      </en-collapse-item>
    </en-collapse>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnospraySprayDefinitions['WorkOrderDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  }
})
</script>
